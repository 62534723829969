<template>
  <v-row class="login-page container--fluid">
    <v-col class="login-part col-12 col-md-6 col-lg-7">
      <LoginForm
        :logintype="'admin'"
        @login="login($event)"
        :alert="alert"
        :accountlist="accountlist"
        :displayLogin="displayLogin"
        @onSelectAccount="onSelectAccount($event)"
        @closeError="alert = {}"
        welcomeText="Admin Portal"
        welcomeSubText=""
        :isUserClosedOrSuspended="isUserExist"
        :message="showMessage"
        :alertType="status"
        @backToLogin="onBackToLogin"
      ></LoginForm>
    </v-col>
    <v-col class="col-md-6 col-lg-5 login-right">
      <div class="login-img"></div>
    </v-col>
  </v-row>
</template>
<script>
import LoginForm from "@components/login/LoginForm.vue";
import { loginEmailPW } from "@/api";
import { knock } from "@/plugins/knock";

import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("isLoading");
export default {
  name: "LoginView",

  components: {
    LoginForm,
  },

  data() {
    return {
      alert: {
        type: "",
        message: "",
      },
      accountlist: [],
      displayLogin: true,
      isUserExist: false,
      showMessage: "",
      status: "",
    };
  },
  computed: {},
  created() {},
  methods: {
    ...mapActions(["setLoadingStatus"]),
    async login(event) {
      this.setLoadingStatus(true);

      loginEmailPW(event)
        .then((result) => {
          const user = result.data.user;
          if (
            result.data.user.state === "closed" ||
            result.data.user.state === "blocked"
          ) {
            this.isUserExist = true;
            this.showMessage = `The user with email ${user.email} is closed/blocked at the level of the system.`;
            this.status = "error";
            return;
          } else if (result.data.user.state === "suspended") {
            this.isUserExist = true;
            this.showMessage = `Account is suspended, please contact EXiQtive support.`;
            this.status = "error";
            return;
          }

          knock.authenticate(user.email);

          let knockFeed = knock.feeds.initialize(
            process.env.VUE_APP_KNOCK_FEED_CHANNEL_ID,
            {
              archived: "exclude",
              page_size: 25,
            }
          );

          knockFeed.listenForUpdates();

          knockFeed.on("items.received.realtime", ({ items }) => {
            console.log(items);
          });

          localStorage.clear();
          localStorage.setItem("token", result.data.token);
          localStorage.setItem("user", JSON.stringify(user));
          this.$router.push("/accounts");
        })
        .catch((error) => {
          if (error.response?.data.error) {
            this.alert = {
              type: "error",
              message: error.response.data.error,
            };
          } else {
            this.alert = {
              type: "error",
              message: "Please try again, something went wrong.",
            };
          }
        })
        .finally(() => {
          this.setLoadingStatus(false);
        });
    },
    onBackToLogin() {
      this.isUserExist = false;
    },
  },
};
</script>
<style lang="scss">
.login-page {
  height: 100%;
  display: flex;
  background: #ffffff;
  margin: 0 !important;
  .login-part {
    padding: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    .login-title {
      &__text {
        font-family: inter;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 38px;
        margin-top: 15px;
        color: #101828;
      }
      &__text1 {
        font-family: inter;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #475467;
      }
    }
  }
  .login-right {
    padding: 0;
    padding-left: 5rem;
    margin: 0 auto;
    .login-img {
      background-image: url("~@/assets/login/section.png");
      background-size: contain;
      background-position: right;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
